export const INITIALISE_QUERY = `
  query getPortalInitialInformation {
    userManagement {
      currentUser {
        permissionGrants {
          name
        }
        isExternal
      }
    }
  }
`;
